<template>
  <div>
    <b-card
      class="mb-3 p-2"
    >
      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="9"
        >
          <b-row class="breadcrumbs-top">
            <b-col cols="12">
              <h3 class="custom-header-title float-left pr-1 mb-0">
                Packages / Pricing
              </h3>
            </b-col>
          </b-row>
        </b-col>

        <!-- Content Right -->
        <b-col
          class="content-header-right text-md-right d-md-block d-none mb-1"
          md="3"
          cols="12"
        >
          <b-button
            variant="flat-primary"
            :to="{ name: 'appointment-events-package-create', params: { parent: $route.params.id } }"
            class="button_no_padding"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Create New Package</span>
          </b-button>
        </b-col>
      </b-row>

      <b-table
        ref="refEventPackagesTable"
        class="position-relative min-height-200"
        :items="fetchEventPackages"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        no-sort-reset
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Role -->
        <template #cell(stringID)="data">
          <div class="text-nowrap">
            <b-button
              v-if="canViewThisAction('show', 'EventPackage')"
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="link"
              class="weight-600 align-middle detail-view-id"
              :to="{ name: 'appointment-event-packages-show', params: { parent: $route.params.id, id: data.item._id } }"
            >
              {{ data.item.stringID }}
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="none"
              class="text-bold-black align-middle detail-view-id not-button"
            >
              {{ data.item.stringID }}
            </b-button>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            :variant="`light-${resolveEventPackageStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template
          v-if="canViewThisAction('show', 'EventPackage') || canViewThisAction('update', 'EventPackage') || canViewThisAction('delete', 'EventPackage')"
          #cell(actions)="data"
        >
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-if="canViewThisAction('show', 'EventPackage')"
              :to="{ name: 'appointment-event-packages-show', params: { parent: $route.params.id, id: data.item._id } }"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">View</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="canViewThisAction('update', 'EventPackage')"
              :to="{ name: 'appointment-event-packages-edit', params: { parent: $route.params.id, id: data.item._id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="canViewThisAction('delete', 'EventPackage')"
              @click="deleteEntity(data.item._id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="canViewThisAction('update', 'EventPackage')"
              id="toggle-btn"
              v-b-modal.modal-update-event-package-status
              @click="setPackageDetail(data.item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Update Status</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
    </b-card>

    <div
      v-if="totalEventPackages > perPage"
      class="mx-2 mb-2"
    >
      <b-row>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center pagination-content"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalEventPackages"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            align="center"
            prev-text="Prev"
            next-text="Next"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
              <span>Prev</span>
            </template>
            <template #next-text>
              <span>Next</span>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

    <b-modal
      id="modal-update-event-package-status"
      ref="update-status-modal"
      cancel-variant="flat-primary"
      ok-variant="success"
      ok-title="Update"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Update Status"
      @ok="submitForm"
    >
      <b-form @submit.prevent="submitForm">
        <validation-observer
          ref="eventPackageStatusEditForm"
        >
          <b-form-group
            label="Status*"
            label-for="h-activity-type-status"
          >
            <validation-provider
              #default="{ errors }"
              name="Status"
              vid="status"
              rules="required"
            >
              <v-select
                id="h-activity-type-status"
                v-model="status"
                label="title"
                :options="statusDataOptions"
                :reduce="title => title.code"
                :clearable="false"
                @input="statusValidation == true ? statusValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="statusValidation"
                class="text-danger"
              >
                {{ statusError }}
              </small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BButton, BCol, BTable,
  BBadge, BDropdown, BDropdownItem, BPagination, BModal, BForm, BFormGroup,
} from 'bootstrap-vue'

import { onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'

import { required } from '@validations'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import store from '@/store'
import useEventPackageList from './useEventPackageList'
import eventPackageStoreModule from './eventPackageStoreModule'

export default {
  components: {
    // UserListAddNew,

    BCard,
    BRow,
    BButton,
    BCol,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    BModal,
    BForm,
    BFormGroup,

    vSelect,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      packageID: '',
      stringID: '',
      name: '',
      status: 'available',
      statusValidation: false,
      statusError: 'Valid status is required',
      statusDataOptions: [{ title: 'Available', code: 'available' }, { title: 'Unavailable', code: 'unavailable' }, { title: 'Full', code: 'full' }],

      required,
    }
  },

  methods: {

    setPackageDetail(pack) {
      this.packageID = pack._id
      this.status = pack.status
      this.name = pack.name
      this.stringID = pack.stringID
    },
    submitForm() {
      this.$refs.eventPackageStatusEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('status', this.status)

          this.$http.patch(`appointment/event-packages/${this.packageID}/status/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(() => {
              this.refetchData()
              // this.$nextTick(() => {
              //   this.$refs['update-status-modal'].toggle('#toggle-btn')
              // })
              this.$swal({
                title: 'Status Updated!',
                html: `The status for <strong>${this.stringID} ‘${this.name}’</strong> has been updated`,
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
    deleteEntity(id) {
      this.$swal({
        title: 'Are you sure?',
        html: 'Selecting Delete will <strong>permanently delete</strong> this item. This action cannot be undone.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete!',
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-danger ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.toDeletedID = id
            this.deleteEventPackage()
          }
        })
    },
  },
  setup() {
    const EVENT_PACKAGE_APP_STORE_MODULE_NAME = 'app-event-packages'

    // console.log(props.roleId)

    // Register module
    if (!store.hasModule(EVENT_PACKAGE_APP_STORE_MODULE_NAME)) store.registerModule(EVENT_PACKAGE_APP_STORE_MODULE_NAME, eventPackageStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVENT_PACKAGE_APP_STORE_MODULE_NAME)) store.unregisterModule(EVENT_PACKAGE_APP_STORE_MODULE_NAME)
    })

    const { canViewThisAction } = useAclUtils()

    const {
      fetchEventPackages,
      tableColumns,
      perPage,
      currentPage,
      totalEventPackages,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refEventPackagesTable,
      refetchData,
      currentDataLength,

      toDeletedID,
      deleteEventPackage,

      // UI
      resolveEventPackageStatusVariant,

    } = useEventPackageList()

    return {

      // Sidebar

      fetchEventPackages,
      tableColumns,
      perPage,
      currentPage,
      totalEventPackages,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refEventPackagesTable,
      refetchData,
      toDeletedID,
      deleteEventPackage,
      currentDataLength,

      // Filter
      avatarText,

      // UI
      resolveEventPackageStatusVariant,

      canViewThisAction,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
