import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router/index'

// Notification

export default function useEventPackageList() {
  // Use toast
  const toast = useToast()
  const route = router.history.current

  const refEventPackagesTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'stringID',
      label: 'ID',
      sortable: true,
    },
    {
      key: 'name',
      label: 'name',
      sortable: true,
    },
    {
      key: 'soldQuantity',
      label: 'Sold',
      sortable: true,
    },
    {
      key: 'availableQuantity',
      label: 'Quantity',
      sortable: true,
    },
    {
      key: 'price',
      label: 'Pricing (S$)',
      sortable: true,
    },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(route.query.perPage ?? process.env.VUE_APP_PER_PAGE_CONTENT)
  const totalEventPackages = ref(0)
  const currentPage = ref(route.query.page ?? 1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = ref(route.query.sortBy ?? 'stringID')
  const isSortDirDesc = ref(route.query.sortDesc === 'true')
  const eventFilter = ref(route.params.id || null)
  const toDeletedID = ref(null)
  const currentDataLength = ref(0)
  // let initialLoad = true

  const dataMeta = computed(() => {
    const localItemsCount = refEventPackagesTable.value ? refEventPackagesTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalEventPackages.value,
    }
  })

  const refetchData = () => {
    refEventPackagesTable.value.refresh()
  }

  watch([eventFilter], () => {
    currentPage.value = 1
    refetchData()
  }, { deep: true })

  watch([currentPage], () => {
    refetchData()
  }, { deep: true })

  const fetchEventPackages = (ctx, callback) => {
    store
      .dispatch('app-event-packages/fetchEventPackages', {
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        event: eventFilter.value,
      },
      { root: true })
      .then(response => {
        const { eventPackages, total } = response.data
        currentDataLength.value = eventPackages.length
        callback(eventPackages)
        totalEventPackages.value = total
        // if (!initialLoad) {
        //   router.replace({
        //     query: {
        //       perPage: perPage.value,
        //       page: currentPage.value,
        //       sortBy: sortBy.value,
        //       sortDesc: isSortDirDesc.value,
        //       event: eventFilter.value,
        //     },
        //   }).catch(() => {})
        // }
        // initialLoad = false
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteEventPackage = () => {
    store
      .dispatch('app-event-packages/deleteEventPackage', {
        id: toDeletedID.value,
      },
      { root: true })
      .then(response => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: response.data.message ?? '',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveEventPackageStatusVariant = status => {
    if (status === 'open') return 'success'
    if (status === 'closed') return 'secondary'
    if (status === 'sold out') return 'danger'
    return 'primary'
  }

  return {
    fetchEventPackages,
    tableColumns,
    perPage,
    currentPage,
    totalEventPackages,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refEventPackagesTable,
    currentDataLength,

    resolveEventPackageStatusVariant,
    refetchData,

    toDeletedID,
    deleteEventPackage,
  }
}
