<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title">
              {{ event.stringID }} - {{ event.name }}
            </h1>
            <p class="log_info">
              Created by {{ event.createdBy ? event.createdBy.name : '' }} on {{ dateFormatWithTime(event.createdAt) }}<br>Last updated on {{ dateFormatWithTime(event.updatedAt) }}
            </p>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-md-right d-md-block d-none mb-1"
        md="3"
        cols="12"
      >
        <div class="user_block ac_type">
          <div class="user_avatar" />
          <div class="user_info">
            <h6>Status</h6>
            <h5>{{ event.status }}</h5>
          </div>
          <div class="action_button">
            <b-button
              v-b-modal.modal-update-event-status
              variant="flat-primary"
            >
              <feather-icon
                icon="EditIcon"
                class="mr-50"
                size="16"
              />
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- Table Container Card -->

    <b-card
      class="mb-3 p-2 calllog-details event_details event-particular-detail"
    >
      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="9"
        >
          <h3>Event Details</h3>
        </b-col>

        <b-col
          class="content-header-right text-md-right d-md-block d-none mb-1"
          md="3"
          cols="12"
        >
          <b-button
            variant="flat-primary"
            :to="{ name: 'appointment-events-edit', params: { id: $route.params.id } }"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
        </b-col>
      </b-row>
      <b-table-simple
        borderless
        responsive
      >
        <b-tbody>
          <b-tr>
            <b-th width="17%">
              Event Name
            </b-th>
            <b-td width="32%">
              {{ event.name }}
            </b-td>
            <b-th width="17%">
              Booking Period
            </b-th>
            <b-td width="32%">
              {{ event.bookingPeriod }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Event Name (中文)
            </b-th>
            <b-td>{{ event.nameSG }}</b-td>
            <b-th>
              Timeslots Booking
            </b-th>
            <b-td>{{ resolveTimeSlotBooking(event.timeSlotBooking) }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Start Date
            </b-th>
            <b-td>{{ dateFormat(event.startDate) }}</b-td>
            <b-th>
              Timeslots Introduction
            </b-th>
            <b-td>{{ event.timeSlotIntroduction }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              End Date
            </b-th>
            <b-td>{{ dateFormat(event.endDate) }}</b-td>
          </b-tr>
          <b-tr>
            <b-td
              colspan="4"
              class="no-padding"
            >
              <hr class="dividerHR wider-than-parent">
            </b-td>
          </b-tr>
          <b-tr>
            <b-th valign="top">
              Date & Time Label
            </b-th>
            <b-td valign="top">
              {{ event.dateLabel }}
            </b-td>
            <b-th valign="top">
              Description
            </b-th>
            <b-td
              class="line-breaks-textarea"
              valign="top"
            >
              {{ event.description }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Date & Time Label (中文)
            </b-th>
            <b-td>{{ event.dateLabelSG }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Location
            </b-th>
            <b-td>{{ event.location }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Location (中文)
            </b-th>
            <b-td>{{ event.locationSG }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Tags
            </b-th>
            <b-td>
              <b-button
                v-for="(tag, key) in event.tags"
                :key="key"
                :title="tag"
                variant="outline-danger"
                class="small-spacing-button mb-50 mt-50"
              >
                {{ tag }}
              </b-button>
            </b-td>
            <b-th>
              Event Slug
            </b-th>
            <b-td>
              <router-link
                :to="{ name: 'event-detail-frontend', params: { slug: event.slug || 0 }}"
                class="btn-link"
                target="_blank"
              >
                {{ baseURL }}/{{ event.slug }}
              </router-link>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td
              colspan="4"
              class="no-padding"
            >
              <hr class="dividerHR wider-than-parent">
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Feature Image
            </b-th>
            <b-td>
              <div class="clearfix">
                <b-img
                  v-for="(url, key) in event.imageURLs"
                  :key="key"
                  class="mb-1 mb-sm-0"
                  height="120"
                  :src="url"
                />
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>

    <b-tabs
      pills
      class="events_tab event_details_page"
    >

      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <span class="d-sm-inline">Packages / Pricing</span>
        </template>
        <package-tab
          class="mt-2 pt-75"
        />
      </b-tab>

      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <span class="d-sm-inline">Bookable Timeslots</span>
        </template>
        <time-slot-tab class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Social -->
      <b-tab>
        <template #title>
          <span class="d-sm-inline">Bookings</span>
        </template>
        <booking-tab class="mt-2 pt-75" />
      </b-tab>
    </b-tabs>

    <b-modal
      id="modal-update-event-status"
      ref="update-status-modal"
      modal-class="status-update-modal"
      cancel-variant="flat-primary"
      ok-variant="success"
      ok-title="Update"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Update Status"
      @ok="submitForm"
    >
      <b-form @submit.prevent="submitForm">
        <validation-observer
          ref="eventStatusEditForm"
        >
          <b-form-group
            label="Status*"
            label-for="h-event-status"
          >
            <validation-provider
              #default="{ errors }"
              name="Status"
              vid="status"
              rules="required"
            >
              <v-select
                id="h-event-status"
                v-model="status"
                label="title"
                :options="statusDataOptions"
                :reduce="title => title.code"
                :clearable="false"
                @input="statusValidation == true ? statusValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="statusValidation"
                class="text-danger"
              >
                {{ statusError }}
              </small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BButton, BCol, BTableSimple, BTbody, BTr, BTh, BTd, BImg, BTab, BTabs, BForm, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import PackageTab from './package/PackageIndex.vue'
import TimeSlotTab from './time-slot/TimeSlotIndex.vue'
import BookingTab from './booking/BookingIndex.vue'

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BImg,
    BTab,
    BTabs,
    BForm,
    BFormGroup,

    vSelect,
    ValidationProvider,
    ValidationObserver,

    PackageTab,
    TimeSlotTab,
    BookingTab,
  },
  data() {
    return {
      event: {},
      baseURL: window.location.origin,
      eventID: '',
      typeID: '',
      name: '',
      status: 'published',
      statusValidation: false,
      statusError: 'Valid status is required',
      statusDataOptions: [
        { title: 'Published', code: 'published' },
        { title: 'Unpublished', code: 'unpublished' },
        { title: 'Set As Draft', code: 'draft' },
      ],

      required,
    }
  },
  created() {
    this.$http.get(`appointment/events/${this.$route.params.id}/show`)
      .then(response => {
        this.event = response.data || {}
        this.setEvent()
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  mounted() {
    window.scrollTo(0, 0)
  },
  methods: {

    setEvent() {
      this.eventID = this.event._id
      this.status = this.event.status
      this.typeID = this.event.stringID
      this.name = this.event.name
    },

    submitForm() {
      this.$refs.eventStatusEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('status', this.status)

          this.$http.patch(`appointment/events/${this.eventID}/status/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              this.event.status = response.data.data.status || 'published'
              this.setEvent()
              // this.$nextTick(() => {
              //   this.$refs['update-status-modal'].toggle('#toggle-btn')
              // })
              this.$swal({
                title: 'Status Updated!',
                html: `The status for <strong>${this.typeID} ‘${this.name}’</strong> has been updated`,
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
    resolveOptionValue(val) {
      if (val === 'file') return 'Image'
      if (val === 'text') return 'Title and Text'
      if (val === 'textarea') return 'Short Answer Field'
      if (val === 'checkbox') return 'Checkbox Field'
      return '-'
    },

    resolveTimeSlotBooking(val) {
      if (val === 'required') return 'Required'
      if (val === 'optional') return 'Optional'
      if (val === 'not available') return 'N/A'
      return '-'
    },
  },
}
</script>
