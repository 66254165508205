import { ref, watch, computed } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import store from '@/store'
import router from '@/router/index'

// Notification

export default function useEventTimeSlotList() {
  // Use toast
  const toast = useToast()
  const route = router.history.current

  const refEventTimeSlotsTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'stringID',
      label: 'ID',
      sortable: true,
    },
    {
      key: 'start',
      label: 'Start Date/Time',
      sortable: true,
    },
    {
      key: 'end',
      label: 'End Date/Time',
      sortable: true,
    },
    {
      key: 'bookedQuantity',
      label: 'Bookings',
      sortable: true,
    },
    {
      key: 'availableQuantity',
      label: 'Quantity',
      sortable: true,
    },
    {
      key: 'duration',
      label: 'Duration (hr)',
      sortable: true,
    },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(route.query.perPage ?? process.env.VUE_APP_PER_PAGE_CONTENT)
  const totalEventTimeSlots = ref(0)
  const currentPage = ref(route.query.page ?? 1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = ref(route.query.sortBy ?? 'start')
  const isSortDirDesc = ref(route.query.sortDesc === 'true')
  const eventFilter = ref(route.params.id || null)
  const toDeletedID = ref(null)
  const currentDataLength = ref(0)
  // let initialLoad = true

  const dataMeta = computed(() => {
    const localItemsCount = refEventTimeSlotsTable.value ? refEventTimeSlotsTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalEventTimeSlots.value,
    }
  })

  const refetchData = () => {
    refEventTimeSlotsTable.value.refresh()
  }

  watch([eventFilter], () => {
    currentPage.value = 1
    refetchData()
  }, { deep: true })

  watch([currentPage], () => {
    refetchData()
  }, { deep: true })

  const fetchEventTimeSlots = (ctx, callback) => {
    store
      .dispatch('app-event-time-slots/fetchEventTimeSlots', {
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        event: eventFilter.value,
      },
      { root: true })
      .then(response => {
        const { eventTimeSlots, total } = response.data
        currentDataLength.value = eventTimeSlots.length
        callback(eventTimeSlots)
        totalEventTimeSlots.value = total
        // if (!initialLoad) {
        //   router.replace({
        //     query: {
        //       perPage: perPage.value,
        //       page: currentPage.value,
        //       sortBy: sortBy.value,
        //       sortDesc: isSortDirDesc.value,
        //       event: eventFilter.value,
        //     },
        //   }).catch(() => {})
        // }
        // initialLoad = false
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteEventTimeSlot = () => {
    store
      .dispatch('app-event-time-slots/deleteEventTimeSlot', {
        id: toDeletedID.value,
      },
      { root: true })
      .then(response => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: response.data.message ?? '',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveEventTimeSlotStatusVariant = status => {
    if (status === 'available') return 'success'
    if (status === 'unavailable') return 'secondary'
    if (status === 'full') return 'danger'
    return 'primary'
  }

  return {
    fetchEventTimeSlots,
    tableColumns,
    perPage,
    currentPage,
    totalEventTimeSlots,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refEventTimeSlotsTable,
    currentDataLength,

    resolveEventTimeSlotStatusVariant,
    refetchData,

    toDeletedID,
    deleteEventTimeSlot,
  }
}
