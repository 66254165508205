import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchEventTimeSlots(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('appointment/event-time-slots', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchEventTimeSlot(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`appointment/event-time-slots/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteEventTimeSlot(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`appointment/event-time-slots/${id}/delete`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
